import * as React from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";

const AGBPage = () => {
  return (
    <Layout isSinglePage={true}>
      <SEO
        keywords={[`gatsby`, `tailwind`, `react`, `tailwindcss`]}
        title="Home"
      />
      <section id="agb"></section>
    </Layout>
  );
};

export default AGBPage;
